<template>
  <div class="develop-container">
    <h2>查询问题反馈视频</h2>
    <div>
      <div><label>PAAS节点:</label></div>
      <template v-for="(node) in paasNodes">
        <span style="margin-right: 10px;">
          <input type="radio" :value="node" v-model="paasNode" class="my-btn"/>{{ node.nodeName }}
        </span>
      </template>
    </div>
    <div>accountId: {{ paasNode.accountId }}</div>
    <div>tenantId: {{ paasNode.tenantId }}</div>
    <div>
      <span>adminId:</span>
      <input type="text" v-model="adminId" style="width: 400px"/>
    </div>
    <div class="line">
      <button @click="onQuery">查询</button>
    </div>
    <hr/>
    <div v-if="queryResult">
      <pre>{{ JSON.stringify(queryResult, null, 2) }}</pre>
    </div>
  </div>
</template>

<script>
import request from "@/utils/request";

const paasNodes = [
  {nodeName: "staging-cn", accountId: "paas_owned", tenantId: "paas_owned"},
  {nodeName: "staging-eu", accountId: "paas_owned", tenantId: "paas_owned"},
  {nodeName: "staging-us", accountId: "paas_owned", tenantId: "paas_owned"},
  {nodeName: "prod-cn", accountId: "paas_owned", tenantId: "paas_owned"},
  {nodeName: "prod-eu", accountId: "paas_owned", tenantId: "paas_owned"},
  {nodeName: "prod-us", accountId: "paas_owned", tenantId: "paas_owned"},
];

export default {
  data() {
    return {
      paasNode: paasNodes[0],
      paasNodes: paasNodes,
      adminId: null,
      queryResult: null
    };
  },
  methods: {
    async onQuery() {
      const resp = await this.queryQuestionBack(this.adminId);
      this.message = resp.message;
      if (resp.code == 0) {
        this.queryResult = resp.data;
      }
    },
    async queryQuestionBack(adminId) {
      const requestConfig = {
        url: "/aiDemo/invokePaasApi",
        method: "post",
        params: {
          accountId: this.paasNode.accountId,
          tenantId: this.paasNode.tenantId,
          nodeName: this.paasNode.nodeName,
          path: `/work/queryQuestionBackVideos`,
          method: "POST"
        },
        data: {
          adminId: adminId
        }
      };
      return await request(requestConfig);
    }
  },
  computed: {},
  watch: {
    paasNode() {

    }
  }
};
</script>

<style lang="scss" scoped>

.my-btn {
  padding: 3px 5px;
  margin: 5px 10px;
  font-size: 16px;
}

</style>
